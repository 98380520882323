const colors = {
    black: '#000',
    white: '#fff',
    red: '#ff5252',
    orange: '#ff7f22',
    blue: '#1a7cff',
    purple: '#8621f6',
    gray: '#101016',
    inputBorder: '#E6E9EE',
    text: '#000',
    placeholder: '#bec2c9',
};

export default colors;
