import { createSlice } from '@reduxjs/toolkit';
import formatPhoneNumber from 'utils/formatPhoneNumber.ts';
import { getMyPageInformation } from '../../../apis/myPage/myPageAPI';

const initialState = {
    loading: true,
    data: {
        salt: null,
        owner_name: '',
        tel: '',
    },
    originalData: {
        owner_name: '',
        tel: '',
    },
    profiles_id: '',
    error: null,
};

const myPageSlice = createSlice({
    name: 'myPage',
    initialState,
    reducers: {
        updateTel(state, action) {
            state.originalData = { ...state.originalData, tel: action.payload };
        },
        updatePasswordState(state, action) {
            state.data.salt = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(getMyPageInformation.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getMyPageInformation.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload.myPage;
            state.originalData = action.payload.myPage;
            state.data.tel = formatPhoneNumber(state.data.tel);
            state.profiles_id = action.payload.myPage.Profiles[0].profiles_id;
            state.error = null;
        });
        builder.addCase(getMyPageInformation.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const { updateTel, updatePasswordState } = myPageSlice.actions;

export default myPageSlice;
