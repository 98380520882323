import { createSlice } from '@reduxjs/toolkit';
import renderCategoryName from 'utils/renderCategoryName';
import { getEditInfo } from '../../../apis/editAPI';

const initialState = {
    loading: true,
    categoryData: [
        {
            id: null,
            order: '',
            text: '',
        },
    ],
    unsavedCategoryData: [
        {
            id: null,
            order: '',
            text: '',
        },
    ],
    error: null,
};

const categorySlice = createSlice({
    name: 'edit_category',
    initialState,
    reducers: {
        updateUnSavedCategoriesOrder(state, action) {
            const elem = state.unsavedCategoryData[action.payload.dragIndex];
            state.unsavedCategoryData.splice(action.payload.dragIndex, 1);
            state.unsavedCategoryData.splice(
                action.payload.hoverIndex,
                0,
                elem,
            );
            state.unsavedCategoryData.forEach((category, index) => {
                category.order = index + 1;
            });
        },
        updateCategoriesOrder(state, action) {
            state.categoryData = state.unsavedCategoryData;
        },
        backToSavedCategoryData(state) {
            state.unsavedCategoryData = state.categoryData;
        },
    },
    extraReducers: builder => {
        builder.addCase(getEditInfo.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getEditInfo.fulfilled, (state, action) => {
            const arr = [];
            action.payload.ProfileCategories.forEach(category => {
                if (category.category_name === '1') return;
                arr.push({
                    id: category.id,
                    order: category.order,
                    text: renderCategoryName(category.category_name),
                });
            });
            state.categoryData = arr;
            state.unsavedCategoryData = arr;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getEditInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const {
    updateUnSavedCategoriesOrder,
    updateCategoriesOrder,
    backToSavedCategoryData,
} = categorySlice.actions;
export default categorySlice;
