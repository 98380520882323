import { createSlice, current } from '@reduxjs/toolkit';
import produce from 'immer';
import { getPortfolioDetail } from '../../apis/viewAPI';

const initialState = {
    loading: true,
    data: {
        ContentOfThePortfolios: [
            {
                contents: {
                    url: [
                        {
                            url: '',
                            urlName: '',
                            id: null,
                        },
                    ],
                    memoir: {},
                },
            },
        ],
        personnel: null,
        portfolioRole: '',
        projectType: '',
        periodStart: '',
        periodEnd: '',
        PERIOD_DIFF: null,
        contribution: '',
        contents: '',
        portfolioName: '',
    },
    error: null,
};

const viewPortfolioSlice = createSlice({
    name: 'viewPortfolio',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPortfolioDetail.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getPortfolioDetail.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            const nextState = produce(action.payload, draft => {
                draft.ContentOfThePortfolios[0].contents = JSON.parse(
                    draft.ContentOfThePortfolios[0].contents,
                );
            });
            state.data = nextState;
        });
        builder.addCase(getPortfolioDetail.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export default viewPortfolioSlice;
