import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    email: '',
};

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setEmailToLogin(state, action) {
            state.email = action.payload;
        },
    },
});

export const { setEmailToLogin } = loginSlice.actions;
export default loginSlice;
