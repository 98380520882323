import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'utils/Axios';

export const getViewInfo = createAsyncThunk(
    'view/getViewInfo',
    async (profileId: string) => {
        const response = await Axios.get(`/api/${profileId}`);

        return response.data;
    },
);

export const getViewMeta = async (profiles_id: string) => {
    const response = Axios.get(`/api/user/og-data`, {
        params: { profiles_id },
    });
    return response;
};
export const getPortfolioDetail = createAsyncThunk(
    'view/getPortfolioDetail',
    async portfolioID => {
        const response = await Axios.get(`/api/portfolio/${portfolioID}`);
        return response.data;
    },
);

export const postSNS = async (formData: FormData) => {
    const response = Axios.post('/api/multiLink/regist', formData);
    return response;
};
