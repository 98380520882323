import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import {
    portfolioPlanBasicImage,
    portfolioDevelopmentBasicImage,
    portfolioDesignBasicImage,
    portfolioMarketingBasicImage,
    portfolioEtcBasicImage,
} from '../../../../public/img/s3Images';
import { getEditInfo } from '../../../apis/editAPI';

const initialUrlItem = {
    id: 1,
    url: '',
    urlName: '',
};

export const initialDetailContentsForm = {
    url: [
        // {
        //     id : 1,
        //     url: '',
        //     urlName: '',
        // },
    ],
    memoir: {
        // type: 'template',
        type: 'directly',
        templateContent: {
            answer1: '',
            answer2: '',
            answer3: '',
            answer4: '',
            answer5: '',
        },
        directlyContent: '',
    },
};

const initialNewPortfolioItem = {
    order: null,
    portfolio_name: '',
    project_type: '',
    image: null,
    encodedImage: null,
    original_image_name: '',
    periodStart: '',
    periodEnd: '',
    portfolioRole: '',
    personnel: '',
    contribution: '',
    contents: '',
    newUrl: initialUrlItem,

    ContentOfThePortfolios: [
        {
            id: null,
            // pdf
            image: null,
            original_image_name: '',
            // json
            contents: initialDetailContentsForm,
            // contents 복사본
            contentsInput: initialDetailContentsForm,
        },
    ],
    fold: null,
    itemWritingRequired: true,
};

const initialState = {
    loading: true,
    portfolioActive: true,
    portfolioData: [],
    newItem: null,
    categoryId: null,
    portfolioEditState: null,
    basicImages: {
        plan: portfolioPlanBasicImage,
        development: portfolioDevelopmentBasicImage,
        design: portfolioDesignBasicImage,
        marketing: portfolioMarketingBasicImage,
        etc: portfolioEtcBasicImage,
    },
    portfolioWritingRequired: null,
    error: null,
};

const portfolioSlice = createSlice({
    name: 'edit_portfolio',
    initialState,
    reducers: {
        changePortfolioSummariesValue(state, action) {
            console.log(action.payload);
            const nextState = produce(state.portfolioData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );
                theItem[action.payload.name] = action.payload.value;
            });
            state.portfolioData = nextState;
        },
        changePortfolioSummariesValueInNewItem(state, action) {
            const nextState = produce(state.newItem, draft => {
                draft[action.payload.name] = action.payload.value;
            });
            state.newItem = nextState;
        },
        changePortfolioDetailInNewItem(state, action) {
            const nextState = produce(state.newItem, draft => {
                draft.ContentOfThePortfolios[0][action.payload.name] =
                    action.payload.value;
            });
            state.newItem = nextState;
        },
        changePortfolioDetailInSavedItem(state, action) {
            const nextState = produce(state.portfolioData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );

                theItem.ContentOfThePortfolios[0][action.payload.name] =
                    action.payload.value;
            });
            state.portfolioData = nextState;
        },
        changeUrlValue(state, action) {
            console.log(state.portfolioData, action.payload);
            const nextState = produce(state.portfolioData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );
                console.log(theItem);
                if (!theItem.ContentOfThePortfolios[0].contentsInput) {
                    const urlItem = { ...initialUrlItem };
                    urlItem[action.payload.name] = action.payload.value;
                    theItem.ContentOfThePortfolios[0].contentsInput = {
                        url: [urlItem],
                    };
                } else {
                    console.log(
                        theItem.ContentOfThePortfolios[0].contentsInput.url,
                    );
                    const theURLInput =
                        theItem.ContentOfThePortfolios[0].contentsInput.url.find(
                            (item, index) => item.id === action.payload.urlId,
                        );
                    console.log(theURLInput);
                    theURLInput[action.payload.name] = action.payload.value;
                }
            });
            state.portfolioData = nextState;
        },
        changeUrlValueInNewItem(state, action) {
            const nextState = produce(state.newItem, draft => {
                draft.newUrl[action.payload.name] = action.payload.value;
            });
            state.newItem = nextState;
        },
        changeNewUrlValueInSavedItem(state, action) {
            const nextState = produce(state.portfolioData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );

                theItem.newUrl[action.payload.name] = action.payload.value;
            });
            state.portfolioData = nextState;
        },
        changeMemoirValue(state, action) {
            const nextState = produce(state.portfolioData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );
                if (action.payload.name.includes('answer')) {
                    theItem.ContentOfThePortfolios[0].contentsInput.memoir.templateContent[
                        action.payload.name
                    ] = action.payload.value;
                    return;
                }
                theItem.ContentOfThePortfolios[0].contentsInput.memoir[
                    action.payload.name
                ] = action.payload.value;
            });
            state.portfolioData = nextState;
        },
        changeMemoirValueInNewItem(state, action) {
            const nextState = produce(state.newItem, draft => {
                if (action.payload.name.includes('answer')) {
                    draft.ContentOfThePortfolios[0].contentsInput.memoir.templateContent[
                        action.payload.name
                    ] = action.payload.value;
                    return;
                }
                draft.ContentOfThePortfolios[0].contentsInput.memoir[
                    action.payload.name
                ] = action.payload.value;
            });
            state.newItem = nextState;
        },
        changeWritingRequired(state, action) {
            state.portfolioWritingRequired = action.payload;
        },
        changeItemWritingRequired(state, action) {
            state.portfolioData = state.portfolioData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          itemWritingRequired: action.payload.value,
                      }
                    : item,
            );
        },
        foldItem(state, action) {
            state.portfolioData = state.portfolioData.map(item =>
                item.id === action.payload ? { ...item, fold: true } : item,
            );
        },
        unFoldItem(state, action) {
            state.portfolioData = state.portfolioData.map(item =>
                item.id === action.payload ? { ...item, fold: false } : item,
            );
        },
        createNewPortfolioItem(state) {
            state.newItem = initialNewPortfolioItem;
        },
        createURLItem(state, action) {
            const nextState = produce(state.portfolioData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );
                const urls = theItem.ContentOfThePortfolios[0].contents.url;
                const ids = theItem.ContentOfThePortfolios[0].contents.url.map(
                    item => item.id,
                );

                urls[urls.length] = {
                    id:
                        theItem.ContentOfThePortfolios[0].contents.url
                            .length !== 0
                            ? Math.max(...ids) + 1
                            : 1,
                    url: '',
                    urlName: '',
                    passed: null,
                };
            });
            state.portfolioData = nextState;
        },
        createNewUrlItem(state, action) {
            const nextState = produce(state.portfolioData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );
                if (theItem) {
                    theItem.newUrl = {
                        url: '',
                        urlName: '',
                    };
                }
            });
            state.portfolioData = nextState;
        },
        updateActiveState(state, action) {
            state.portfolioActive = action.payload;
        },
        updatePortfolioOrder(state, action) {
            const elem = state.portfolioData[action.payload.dragIndex];
            state.portfolioData.splice(action.payload.dragIndex, 1);
            state.portfolioData.splice(action.payload.hoverIndex, 0, elem);
            state.portfolioData.forEach((category, index) => {
                category.order = state.portfolioData.length - index;
            });
        },
        deletePortfolioItemAction(state, action) {
            state.portfolioData = state.portfolioData.filter(
                item => item.id !== action.payload,
            );

            if (state.portfolioData.length === 0) {
                state.newItem = initialNewPortfolioItem;
            }
        },
        deleteNewPortfolioItem(state) {
            delete state.newItem;
        },
        deleteURLItem(state, action) {
            const nextState = produce(state.portfolioData, draft => {
                console.log(action.payload);
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );

                theItem.ContentOfThePortfolios[0].contents.url =
                    theItem.ContentOfThePortfolios[0].contents.url.filter(
                        item => item.id !== action.payload.urlID,
                    );
                //
                theItem.ContentOfThePortfolios[0].contentsInput =
                    theItem.ContentOfThePortfolios[0].contents;
            });
            state.portfolioData = nextState;
        },
        moveNewItemToDataFromServer(state) {
            state.portfolioData = [state.newItem].concat(state.portfolioData);
            state.newItem = null;
        },
        moveNewUrlItemToSavedItem(state, action) {
            if (!action.payload.itemID) {
                const nextState = produce(state.newItem, draft => {
                    draft.ContentOfThePortfolios[0].contentsInput.url =
                        draft.ContentOfThePortfolios[0].contents.url.concat(
                            draft.newUrl,
                        );
                    draft.newUrl = null;
                });
                state.newItem = nextState;
                return;
            }
            const nextState = produce(state.portfolioData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );

                theItem.ContentOfThePortfolios[0].contentsInput.url =
                    theItem.ContentOfThePortfolios[0].contents.url.concat(
                        theItem.newUrl,
                    );
                theItem.newUrl = null;
            });
            state.portfolioData = nextState;
        },
        synchronizeServerDataWithGlobalstate(state, action) {
            if (action.payload === 'newItem') {
                state.newItem.ContentOfThePortfolios[0].contents =
                    state.newItem.ContentOfThePortfolios[0].contentsInput;
            } else {
                const nextState = produce(state.portfolioData, draft => {
                    const theItem = draft.find(
                        item => item.id === action.payload.itemID,
                    );
                    theItem.ContentOfThePortfolios[0].contents =
                        theItem.ContentOfThePortfolios[0].contentsInput;
                });
                state.portfolioData = nextState;
            }
        },
        actionAfterCreateDetailWithUrl(state) {
            state.newItem.ContentOfThePortfolios[0].contents.url =
                state.newItem.ContentOfThePortfolios[0].contents.url.concat(
                    state.newItem.newUrl,
                );
            //
            state.newItem.ContentOfThePortfolios[0].contentsInput =
                state.newItem.ContentOfThePortfolios[0].contents;
            //
            state.newItem.newUrl = null;
        },
        actionAfterPatchDetailWithUrl(state, action) {
            const nextState = produce(state.portfolioData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );

                theItem.ContentOfThePortfolios[0].contents =
                    action.payload.contents;
                theItem.ContentOfThePortfolios[0].contentsInput =
                    action.payload.contents;
                // url을 생성했는지 아니면 그냥 빈 url이었는지
                theItem.newUrl =
                    theItem.newUrl &&
                    (theItem.newUrl.url || theItem.newUrl.urlName)
                        ? null
                        : theItem.newUrl;
            });
            state.portfolioData = nextState;
        },
    },
    extraReducers: builder => {
        builder.addCase(getEditInfo.pending, state => {
            state.loading = true;
            state.error = null;
        });
        // json 받아올 때 파싱 , 서버에 주기 직전에 stringify
        builder.addCase(getEditInfo.fulfilled, (state, action) => {
            let arr = [];
            action.payload.ProfileCategories.forEach(category => {
                if (category.category_name === '1') {
                    arr = category.Portfolios;
                    state.categoryId = category.id;
                    state.portfolioActive = category.active;
                }
            });
            arr = arr.map(item => {
                const {
                    portfolio_name,
                    project_type,
                    period_end,
                    portfolioRole,
                    personnel,
                    contribution,
                } = item;

                const isNeedWriting = [
                    portfolio_name,
                    project_type,
                    period_end,
                    portfolioRole,
                    personnel,
                    contribution,
                ].some(item => !item);

                return {
                    ...item,
                    fold: true,
                    portfolioName: item.portfolio_name,
                    projectType: item.project_type,
                    periodStart: item.period_start,
                    periodEnd: item.period_end,
                    // 포트폴리오 썸네일
                    img: item.image,
                    itemWritingRequired: !!isNeedWriting,
                };
            });

            arr = produce(arr, draft => {
                for (let i = 0; i < draft.length; i++) {
                    const { ContentOfThePortfolios } = draft[i];

                    ContentOfThePortfolios[0].contents = JSON.parse(
                        ContentOfThePortfolios[0].contents,
                    );
                    //
                    if (!ContentOfThePortfolios[0].contents) {
                        ContentOfThePortfolios[0].contents =
                            initialDetailContentsForm;
                    }
                    //
                    ContentOfThePortfolios[0].contentsInput =
                        ContentOfThePortfolios[0].contents;
                    //
                    if (!ContentOfThePortfolios[0].contents?.url) {
                        draft[i].newUrl = initialUrlItem;
                    }
                }
            });
            state.portfolioData = arr;
            if (state.portfolioData.length === 0) {
                state.newItem = initialNewPortfolioItem;
            }

            //
            const isWritingRequired = state.portfolioData.some(
                item => item.itemWritingRequired,
            );
            state.portfolioWritingRequired = !!isWritingRequired;
            state.loading = false;
            state.error = null;
        });

        builder.addCase(getEditInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const {
    changePortfolioSummariesValue,
    changePortfolioSummariesValueInNewItem,
    changePortfolioDetailInNewItem,
    changePortfolioDetailInSavedItem,
    changeUrlValue,
    changeUrlValueInNewItem,
    changeNewUrlValueInSavedItem,
    changeMemoirValue,
    changeMemoirValueInNewItem,
    changeWritingRequired,
    changeItemWritingRequired,
    foldItem,
    unFoldItem,
    createNewPortfolioItem,
    createURLItem,
    createNewUrlItem,
    updateActiveState,
    updatePortfolioOrder,
    deletePortfolioItemAction,
    deleteNewPortfolioItem,
    deleteURLItem,
    moveNewItemToDataFromServer,
    moveNewUrlItemToSavedItem,
    synchronizeServerDataWithGlobalstate,
    actionAfterCreateDetailWithUrl,
    actionAfterPatchDetailWithUrl,
} = portfolioSlice.actions;
export default portfolioSlice;
