import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { getEditInfo } from '../../../apis/editAPI';

const initialNewCertificateItem = {
    order: null,
    certificate_name: '',
    issuer: '',
    acquisition_date: '',
    evidence: null,
    original_image_name: '',
    fold: null,
    itemWritingRequired: true,
};

const initialState = {
    loading: true,
    certificateActive: true,
    certificateData: [
        {
            id: null,
        },
    ],
    newItem: null,
    categoryId: null,
    certificateWritingRequired: null,
    error: null,
};

const certificateSlice = createSlice({
    name: 'edit_certificate',
    initialState,
    reducers: {
        changeCertificateValue(state, action) {
            const nextState = produce(state.certificateData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );
                theItem[action.payload.name] = action.payload.value;
            });
            state.certificateData = nextState;
        },
        changeCertificateValueInNewItem(state, action) {
            const nextState = produce(state.newItem, draft => {
                draft[action.payload.name] = action.payload.value;
            });
            state.newItem = nextState;
        },
        changeValueInNewItem(state, action) {
            state.newItem[action.payload.name] = action.payload.value;
        },
        changeWritingRequired(state, action) {
            state.certificateWritingRequired = action.payload;
        },
        changeItemWritingRequired(state, action) {
            state.certificateData = state.certificateData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          itemWritingRequired: action.payload.value,
                      }
                    : item,
            );
        },
        foldItem(state, action) {
            state.certificateData = state.certificateData.map(item =>
                item.id === action.payload ? { ...item, fold: true } : item,
            );
        },
        unFoldItem(state, action) {
            state.certificateData = state.certificateData.map(item =>
                item.id === action.payload ? { ...item, fold: false } : item,
            );
        },
        createNewCertificateItem(state, action) {
            state.newItem = initialNewCertificateItem;
        },
        updateActiveState(state, action) {
            state.certificateActive = action.payload;
        },
        updateCertificateOrder(state, action) {
            const elem = state.certificateData[action.payload.dragIndex];
            state.certificateData.splice(action.payload.dragIndex, 1);
            state.certificateData.splice(action.payload.hoverIndex, 0, elem);
            state.certificateData.forEach((category, index) => {
                category.order = state.certificateData.length - index;
            });
        },
        deleteCertificateItemAction(state, action) {
            state.certificateData = state.certificateData.filter(
                item => item.id !== action.payload,
            );

            if (state.certificateData.length === 0) {
                state.newItem = initialNewCertificateItem;
            }
        },
        deleteNewCertificateItem(state, action) {
            delete state.newItem;
        },
        moveNewItemToDataFromServer(state, action) {
            state.certificateData = [state.newItem].concat(
                state.certificateData,
            );
            state.newItem = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(getEditInfo.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getEditInfo.fulfilled, (state, action) => {
            let arr = [];
            action.payload.ProfileCategories.forEach(category => {
                if (category.category_name === '3') {
                    arr = category.Certificates;
                    state.categoryId = category.id;
                    state.certificateActive = category.active;
                }
            });
            arr = arr.map(item => {
                const isNeedWriting = [
                    item.certificate_name,
                    item.issuer,
                    item.acquisition_date,
                ].some(item => !item);
                return {
                    ...item,
                    certificateName: item.certificate_name,
                    acquisitionDate: item.acquisition_date,
                    itemWritingRequired: !!isNeedWriting,
                    fold: true,
                };
            });
            state.certificateData = arr;
            if (state.certificateData.length === 0) {
                state.newItem = initialNewCertificateItem;
            }

            const isWritingRequired = state.certificateData.some(
                item => item.itemWritingRequired,
            );

            state.certificateWritingRequired = !!isWritingRequired;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getEditInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const {
    changeCertificateValue,
    changeCertificateValueInNewItem,
    changeValueInNewItem,
    changeWritingRequired,
    changeItemWritingRequired,
    foldItem,
    unFoldItem,
    createNewCertificateItem,
    updateActiveState,
    updateCertificateOrder,
    deleteCertificateItemAction,
    deleteNewCertificateItem,
    moveNewItemToDataFromServer,
} = certificateSlice.actions;
export default certificateSlice;
