import { Provider } from 'react-redux';
import 'utils/alert.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'themes/index';
import GlobalStyle from 'styles/globalStyle';
import ErrorBoundary from 'utils/ErrorBoundary';
import * as fbq from 'utils/facebookPixel';
import * as gtag from 'utils/gtag';
import { wrapper } from '../store/store';
import { seoImage } from '../../public/img/s3Images';
import NextNProgress from 'nextjs-progressbar';

const queryClient = new QueryClient();

type PagePropsType = {
    title?: string;
    description?: string;
    image?: string;
    url?: string;
    index?: boolean;
    favicon?: boolean;
};

type AppProps = {
    Component?: React.FC;
    pageProps: PagePropsType;
};

export default function App({ Component, pageProps }: AppProps) {
    console.log(Component, pageProps);
    const router = useRouter();

    useEffect(() => {
        fbq.pageview();
        //
        const handleRouteChange = (url: string) => {
            fbq.pageview();
            gtag.pageview(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);
    //
    const { store, props } = wrapper.useWrappedStore(pageProps);

    const title = pageProps.title ? pageProps.title : '스타팅';
    const originDescription = pageProps.description
        ? pageProps.description
        : 'IT 직군 주니어를 위한 채용 플랫폼, 업무적합성 딱 맞는 기업 매칭, 프로젝트 경험 기반 이력서 제작, 실무 프로젝트 기회까지';
    const description = pageProps.description
        ? pageProps.description
        : '나랑 딱 맞는 기업에서 커리어 시작하기';
    const image = pageProps.image ? pageProps.image : seoImage;
    const twitterDescription = pageProps.description
        ? pageProps.description
        : 'IT 직군 주니어를 위한 채용 플랫폼, 업무적합성 딱 맞는 기업 매칭, 프로젝트 경험 기반 이력서 제작, 실무 프로젝트 기회까지';
    const url = pageProps.url ? pageProps.url : 'https://start-ing.kr';
    const metaRobots = pageProps.index ? 'noindex, nofollow' : 'index, follow';
    const growthonFavicon = !!pageProps.favicon;

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, user-scalable=no, initial-scale=1"
                />
                <meta name="robots" content={metaRobots} />

                <meta name="description" content={originDescription} />
                <meta
                    name="keywords"
                    content="이력서, 직무경험, 포트폴리오, 취업, 채용"
                />

                {/* OG(Kakao, Facebook, Etc) */}
                <meta property="og:site_name" content="Starting" />
                <meta property="og:image" content={image} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="ko_KR" />

                {/* OG(Twitter) */}
                <meta property="twitter:card" content={twitterDescription} />
                <meta property="twitter:title" content={title} />
                <meta
                    property="twitter:description"
                    content={twitterDescription}
                />
                <meta property="twitter:image" content={image} />

                <link
                    rel="shortcut icon"
                    href={
                        growthonFavicon
                            ? 'img/Growthon_favicon.ico'
                            : 'img/favicon.ico'
                    }
                    type="image/x-icon"
                />
            </Head>
            {/* Global Site Code Pixel - Facebook Pixel */}
            <Script
                id="fb-pixel"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
                }}
            />
            <GlobalStyle />
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <Provider store={store}>
                    <ErrorBoundary>
                        <ThemeProvider theme={theme}>
                            <NextNProgress
                                transformCSS={css => {
                                    const modifiedCSS = `${css}
                                      #nprogress .spinner {
                                        display: none;
                                      }`;

                                    // JSX.Element로 반환
                                    return <style>{modifiedCSS}</style>;
                                }}
                            />
                            {Component && <Component {...props} />}
                        </ThemeProvider>
                    </ErrorBoundary>
                </Provider>
            </QueryClientProvider>
        </>
    );
}
