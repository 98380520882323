import Swal from 'sweetalert2';

// toast **************************************************
export function toast(text, iconType) {
    // text : 내용
    // iconType :  1 - check 아이콘, 2 - warn 아이콘
    let icon;
    if (iconType === 1) icon = '/img/checkIcon.png';
    if (iconType === 2) icon = '/img/warnIcon.png';

    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom',
        timer: 2000,
        imageUrl: icon,
        showConfirmButton: false,
        showClass: {
            popup: 'animate__fadeIn',
        },
        hideClass: {
            popup: 'animate__fadeOut',
        },
        customClass: {
            container: 'toast_container',
            popup: 'toast_popup',
            title: 'toast_title',
            htmlContainer: 'toast_htmlContainer',
            image: 'toast_image',
        },
    });
    Toast.fire({
        text,
    });
}

// confirm **************************************************

export function confirm(
    title,
    text,
    confirmButtonText,
    cancelButtonText,
    callback,
    falseCallback,
) {
    // title : 상단 제목
    // text : 제목 하단 내용
    // confirmButtonText : 확인 버튼 텍스트
    // cancelButtonText : 취소 버튼 텍스트
    // callback : 확인 버튼 눌렀을 때 true값 전달

    Swal.fire({
        title,
        text,
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
        reverseButtons: true,
        showClass: {
            popup: 'animate__fadeIn',
        },
        hideClass: {
            popup: 'animate__fadeOut',
        },
        customClass: {
            container: 'confirm_container',
            popup: 'confirm_popup',
            title: 'confirm_title',
            htmlContainer: 'confirm_htmlContainer',
            actions: 'confirm_actions',
            confirmButton: 'confirm_confirmButton',
            cancelButton: 'confirm_cancelButton',
        },
    }).then(res => {
        if (res.isConfirmed) {
            callback(true);
        } else {
            falseCallback && falseCallback();
        }
    });
}

// alert **************************************************

export function alert(title, confirmButtonText, actionFunc, text, titleBold) {
    Swal.fire({
        title,
        text,
        confirmButtonText,
        confirmButtonColor: '#000',
        color: '#000',
        focusConfirm: 'none',
        showClass: {
            popup: 'animate__fadeInUp',
        },
        hideClass: {
            popup: 'animate__fadeInOut',
        },
        customClass: {
            container: 'alert_container',
            popup: 'alert_popup',
            title: titleBold ? 'alert_title_bold' : 'alert_title',
            htmlContainer: 'alert_htmlContainer',
            actions: 'alert_actions',
            confirmButton: 'alert_confirmButton',
        },
    }).then(res => {
        res.isConfirmed && actionFunc && actionFunc();
    });
}
