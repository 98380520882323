import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { getEditInfo } from '../../../apis/editAPI';

const initialNewCoverletterItem = {
    order: null,
    name: '',
    oneLineSummary: '',
    contents: '',
    fold: null,
    itemWritingRequired: true,
};

const initialState = {
    loading: true,
    coverletterActive: true,
    coverletterData: [
        {
            id: null,
            name: '',
            oneLineSummary: '',
            contents: '',
        },
    ],
    newItem: null,
    categoryId: null,
    coverletterWritingRequired: null,
    error: null,
};

const coverletterSlice = createSlice({
    name: 'edit_coverletter',
    initialState,
    reducers: {
        changeValue(state, action) {
            state.coverletterData = state.coverletterData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          [action.payload.name]: action.payload.value,
                      }
                    : item,
            );
        },
        changeValueInNewItem(state, action) {
            state.newItem[action.payload.name] = action.payload.value;
        },
        changeWritingRequired(state, action) {
            state.coverletterWritingRequired = action.payload;
        },
        changeItemWritingRequired(state, action) {
            state.coverletterData = state.coverletterData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          itemWritingRequired: action.payload.value,
                      }
                    : item,
            );
        },
        foldItem(state, action) {
            state.coverletterData = state.coverletterData.map(item =>
                item.id === action.payload ? { ...item, fold: true } : item,
            );
        },
        unFoldItem(state, action) {
            state.coverletterData = state.coverletterData.map(item =>
                item.id === action.payload ? { ...item, fold: false } : item,
            );
        },
        createNewCoverletterItem(state, action) {
            state.newItem = initialNewCoverletterItem;
        },
        updateActiveState(state, action) {
            state.coverletterActive = action.payload;
        },
        updateCoverletterOrder(state, action) {
            const elem = state.coverletterData[action.payload.dragIndex];
            state.coverletterData.splice(action.payload.dragIndex, 1);
            state.coverletterData.splice(action.payload.hoverIndex, 0, elem);
            state.coverletterData.forEach((category, index) => {
                category.order = state.coverletterData.length - index;
            });
        },
        deleteCoverletterItemAction(state, action) {
            state.coverletterData = state.coverletterData.filter(
                item => item.id !== action.payload,
            );

            if (state.coverletterData.length === 0) {
                state.newItem = initialNewCoverletterItem;
            }
        },
        deleteNewCoverletterItem(state, action) {
            delete state.newItem;
        },
        moveNewItemToDataFromServer(state, action) {
            state.coverletterData = [state.newItem].concat(
                state.coverletterData,
            );
            state.newItem = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(getEditInfo.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getEditInfo.fulfilled, (state, action) => {
            let data = action.payload.CoverLetters[0].CoverLetterDetails;
            data = data.map(item => {
                const isNeedWriting = [
                    item.name,
                    item.one_line_summary,
                    item.contents,
                ].some(item => !item);
                return {
                    ...item,
                    oneLineSummary: item.one_line_summary,
                    fold: true,
                    itemWritingRequired: !!isNeedWriting,
                };
            });
            state.coverletterData = data;
            state.categoryId = action.payload.CoverLetters[0].id;
            state.coverletterActive = action.payload.CoverLetters[0].active;
            if (state.coverletterData.length === 0) {
                state.newItem = initialNewCoverletterItem;
            }

            const isWritingRequired = state.coverletterData.some(
                item => item.itemWritingRequired,
            );

            state.coverletterWritingRequired = !!isWritingRequired;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getEditInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const {
    changeValue,
    changeValueInNewItem,
    changeWritingRequired,
    changeItemWritingRequired,
    foldItem,
    unFoldItem,
    createNewCoverletterItem,
    updateActiveState,
    updateCoverletterOrder,
    deleteCoverletterItemAction,
    deleteNewCoverletterItem,
    moveNewItemToDataFromServer,
} = coverletterSlice.actions;
export default coverletterSlice;
