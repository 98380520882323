import { createSlice, current } from '@reduxjs/toolkit';
import { getEditInfo } from '../../../apis/editAPI';

const basicProfileImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/%EA%B8%B0%EB%B3%B8%EC%9D%B4%EB%AF%B8%EC%A7%80.png';

const initialState = {
    loading: true,
    basicInformationData: {
        profileImage: '',
        basicProfileImage,
        name: '',
        job: null,
        detailedJob: null,
        address: null,
        detailedAddress: null,
        educationalInstitution: '',
        educationalStatus: '',
        UserSkills: [],
    },
    basicInfoWritingRequired: null,
    error: null,
};

const basicInformationSlice = createSlice({
    name: 'edit_basicInformation',
    initialState,
    reducers: {
        basicInfoWritingRequiredToFalse(state, action) {
            state.basicInfoWritingRequired = false;
        },
        basicInfoWritingRequiredToTrue(state, action) {
            state.basicInfoWritingRequired = true;
        },
        updateValue(state, action) {
            state.basicInformationData[action.payload.name] =
                action.payload.value;
        },
    },
    extraReducers: builder => {
        builder.addCase(getEditInfo.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getEditInfo.fulfilled, (state, action) => {
            const User = {
                profileImage: action.payload.User.image,
                basicProfileImage,
                name: action.payload.User.owner_name,
                job: action.payload.User.job,
                detailedJob: action.payload.User.detailed_job,
                address: action.payload.User.region,
                detailedAddress: action.payload.User.detailed_region,
                educationalInstitution: action.payload.User.education,
                educationalStatus: action.payload.User.education_status,
                UserSkills: action.payload.User.UserSkills,
            };
            state.basicInformationData = User;

            //
            const {
                detailedJob,
                detailedAddress,
                educationalInstitution,
                educationalStatus,
            } = state.basicInformationData;

            const isNeedWriting = [
                detailedJob,
                detailedAddress,
                educationalInstitution,
                educationalStatus,
            ].some(item => !item);

            state.basicInfoWritingRequired = !!isNeedWriting;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getEditInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const {
    basicInfoWritingRequiredToFalse,
    basicInfoWritingRequiredToTrue,
    updateValue,
} = basicInformationSlice.actions;
export default basicInformationSlice;
