const requiredKeysInCareer = [
    'company_name',
    'work_type',
    'main_responsibilities',
    'inclusive',
    'exclusive',
];

const requiredKeysInDegree = ['degree_name', 'period1', 'period2'];

const requiredKeysInEducation = ['education_name', 'period1', 'period2'];

const requiredKeysInCertificate = [
    'certificate_name',
    'issuer',
    'acquisition_date',
];

const requiredKeysInLanguage = ['foreign_language_name', 'level'];

const requiredKeysInPortfolio = [
    'portfolio_name',
    'project_type',
    'period_end',
    'portfolioRole',
    'personnel',
    'contribution',
];

const requiredKeysInCoverletter = ['name', 'one_line_summary', 'contents'];

const renderRequiredKeyArr = categoryName => {
    switch (categoryName) {
        case '2':
            return requiredKeysInEducation;
        case '3':
            return requiredKeysInCertificate;
        case '4':
            return requiredKeysInLanguage;
        case '5':
            return requiredKeysInDegree;
        case '6':
            return requiredKeysInCareer;
        case 'portfolio':
            return requiredKeysInPortfolio;
        case 'coverletter':
            return requiredKeysInCoverletter;
        default:
            return null;
    }
};

export default renderRequiredKeyArr;
