import Image from 'next/image';
import React from 'react';
import Lottie from 'react-lottie-player';
import styled from 'styled-components';
import * as errorLottie from '../../public/lottie/error_lottie.json';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        // Define a state variable to track whether is an error or not
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI

        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can use your own error logging service here
        console.log({ error, errorInfo });
    }

    render() {
        // Check if the error is thrown

        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <StyledContainer>
                    <StyledContent>
                        <StyledLottie>
                            <Lottie animationData={errorLottie} loop play />
                        </StyledLottie>
                        <StyledImg>
                            <Image
                                src="/img/error/error.png"
                                alt="에러 이미지"
                                width={140}
                                height={140}
                            />
                        </StyledImg>
                        <StyledTextBox>
                            <h2>일시적인 오류인 것 같아요!</h2>
                            <p>
                                자, 침착하게
                                <span> 다시 한 번 시도해보시겠어요?</span>
                            </p>
                        </StyledTextBox>
                        <StyledBtn
                            onClick={() => {
                                if (window === 'undefined') return;
                                window.location.reload();
                            }}
                        >
                            새로고침
                        </StyledBtn>
                    </StyledContent>
                </StyledContainer>
            );
        }

        // Return children components in case of no error

        return this.props.children;
    }
}

export default ErrorBoundary;

const StyledContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background: #f7f8fa;
`;
const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
const StyledLottie = styled.div`
    width: 300px;
    height: 300px;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
const StyledImg = styled.div`
    width: 140px;
    height: 140px;
    display: none;
    margin-bottom: 25px;
    @media screen and (max-width: 767px) {
        display: block;
    }
`;
const StyledTextBox = styled.div`
    text-align: center;
    margin-bottom: 70px;
    @media screen and (max-width: 767px) {
        margin-bottom: 25px;
    }
    h2 {
        font-weight: 800;
        font-size: 60px;
        line-height: 72px;
        margin-bottom: 14.995px;
        @media screen and (max-width: 767px) {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 15px;
        }
    }
    p,
    span {
        text-align: center;
        margin: 0 auto;
        font-weight: 500;
        font-size: 35.002px;
        line-height: 42.01px;
        word-break: keep-all;
        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
            display: block;
        }
    }
`;
const StyledBtn = styled.button`
    padding: 14.995px 24.998px;
    background-color: #000;
    color: #fff;
    font-weight: 700;
    font-size: 25.997px;
    border-radius: 14.995px;
    cursor: pointer;
    @media screen and (max-width: 767px) {
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 15px;
    }
`;
