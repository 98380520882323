import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { getEditInfo } from '../../../apis/editAPI';

export const initialCareerDetailItem = {
    // id 백엔드에서 주는걸로 받아야 함
    id: 1,
    order: 1,
    achievementSummary: '',
    periodStart: '',
    periodEnd: '',
    objective: '',
    process: '',
    result: '',
    jobDescriptionTab: 'objective',
};

const initialNewCareerItem = {
    order: 1,
    company_name: '',
    work_type: null,
    inclusive: '',
    exclusive: '',
    main_responsibilities: '',
    CareerDetails: [initialCareerDetailItem],
    fold: null,
    itemWritingRequired: true,
};

const initialState = {
    loading: true,
    careerActive: true,
    careerData: [
        {
            id: null,
            company_name: '',
            work_type: null,
            inclusive: '',
            exclusive: '',
            main_responsibilities: '',
            CareerDetails: [],
            // newCareerDetail : null,
        },
    ],
    // newItem: initialNewCareerItem,
    categoryId: null,
    careerEditState: null,
    careerWritingRequired: null,
    error: null,
};

const careerSlice = createSlice({
    name: 'edit_career',
    initialState,
    reducers: {
        changeValue(state, action) {
            state.careerData = state.careerData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          [action.payload.name]: action.payload.value,
                      }
                    : item,
            );
        },
        changeValueInNewItem(state, action) {
            const isCareerDetail = [
                'achievementSummary',
                'periodStart',
                'periodEnd',
                'objective',
                'process',
                'result',
            ].includes(action.payload.name);
            if (!isCareerDetail) {
                state.newItem[action.payload.name] = action.payload.value;
            } else {
                state.newItem.CareerDetails[0][action.payload.name] =
                    action.payload.value;
            }
        },
        changeValueInJobDesc(state, action) {
            const nextState = produce(state.careerData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );
                const theDetailItem = theItem.CareerDetails.find(
                    detailItem => detailItem.id === action.payload.detailItemId,
                );
                theDetailItem[action.payload.name] = action.payload.value;
            });
            state.careerData = nextState;
        },
        changeValueInNewCareerDetail(state, action) {
            // 저장이 되지 않은 아이템의 첫번째 careerdetail

            const nextState = produce(state.newItem, draft => {
                if (typeof action.payload.name === 'string') {
                    draft.CareerDetails[0][action.payload.name] =
                        action.payload.value;
                } else {
                    action.payload.name.forEach((name, index) => {
                        draft.CareerDetails[0][name] =
                            action.payload.value[index];
                    });
                }
            });
            state.newItem = nextState;
        },
        changeVaueInNewCareerDetailInSavedItem(state, action) {
            const nextState = produce(state.careerData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );
                action.payload.name.forEach((name, index) => {
                    theItem.newCareerDetail[name] = action.payload.value[index];
                });
            });
            state.careerData = nextState;
        },
        changeWritingRequired(state, action) {
            state.careerWritingRequired = action.payload;
        },
        changeItemWritingRequired(state, action) {
            state.careerData = state.careerData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          itemWritingRequired: action.payload.value,
                      }
                    : item,
            );
        },
        foldItem(state, action) {
            state.careerData = state.careerData.map(item =>
                item.id === action.payload ? { ...item, fold: true } : item,
            );
        },
        unFoldItem(state, action) {
            state.careerData = state.careerData.map(item =>
                item.id === action.payload ? { ...item, fold: false } : item,
            );
        },
        createNewCareerItem(state, action) {
            state.newItem = initialNewCareerItem;
        },
        createNewCareerDetail(state, action) {
            state.careerData = state.careerData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          newCareerDetail: initialCareerDetailItem,
                      }
                    : item,
            );
        },
        updateActiveState(state, action) {
            state.careerActive = action.payload;
        },
        deleteCareerItemAction(state, action) {
            state.careerData = state.careerData.filter(
                item => item.id !== action.payload,
            );
            if (state.careerData.length === 0) {
                state.newItem = initialNewCareerItem;
            }
        },
        deleteNewCareerItem(state, action) {
            delete state.newItem;
        },
        deleteCareerDetailAction(state, action) {
            const nextState = produce(state.careerData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );
                theItem.CareerDetails = theItem.CareerDetails.filter(
                    detailItem => detailItem.id !== action.payload.detailItemId,
                );
            });
            state.careerData = nextState;
        },
        deleteNewCareerDetail(state, action) {
            state.careerData = state.careerData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          newCareerDetail: null,
                      }
                    : item,
            );
        },
        moveNewItemToDataFromServer(state, action) {
            state.careerData = [state.newItem].concat(state.careerData);
            state.newItem = null;
        },
        moveNewDetailItemToDataFromServer(state, action) {
            state.careerData = state.careerData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          CareerDetails: item.CareerDetails.concat(
                              item.newCareerDetail,
                          ),
                          newCareerDetail: null,
                      }
                    : item,
            );
            console.log(state.careerData);
        },
    },
    extraReducers: builder => {
        builder.addCase(getEditInfo.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getEditInfo.fulfilled, (state, action) => {
            action.payload.ProfileCategories.forEach(category => {
                if (category.category_name === '6') {
                    state.categoryId = category.id;
                    state.careerData = category.Careers;
                    state.careerActive = category.active;
                }
            });

            // 작성 필요 검사 & 키값 변경
            // 원래 키값도 그대로 남아 있음
            state.careerData = state.careerData.map(item => {
                const {
                    company_name,
                    work_type,
                    main_responsibilities,
                    inclusive,
                    exclusive,
                } = item;

                const isNeedWriting = [
                    company_name,
                    work_type,
                    main_responsibilities,
                    inclusive,
                    exclusive,
                ].some(item => !item);

                return {
                    ...item,
                    fold: true,
                    companyName: item.company_name,
                    workType: item.work_type,
                    jobDescription: item.job_description,
                    mainResponsibilities: item.main_responsibilities,
                    CareerDetails: item.CareerDetails.map(item => ({
                        ...item,
                        achievementSummary: item.achievement_summary,
                        periodStart: item.period_start,
                        periodEnd: item.period_end,
                        jobDescriptionTab: 'objective',
                    })),
                    itemWritingRequired: !!isNeedWriting,
                };
            });

            if (state.careerData.length === 0) {
                state.newItem = initialNewCareerItem;
            }

            //
            const isWritingRequired = state.careerData.some(
                item => item.itemWritingRequired,
            );
            state.careerWritingRequired = !!isWritingRequired;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getEditInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const {
    changeValue,
    changeValueInNewItem,
    changeValueInJobDesc,
    changeValueInNewCareerDetail,
    changeVaueInNewCareerDetailInSavedItem,
    changeWritingRequired,
    changeItemWritingRequired,
    foldItem,
    unFoldItem,
    createNewCareerItem,
    createNewCareerDetail,
    updateActiveState,
    deleteCareerItemAction,
    deleteNewCareerItem,
    deleteCareerDetailAction,
    deleteNewCareerDetail,
    moveNewItemToDataFromServer,
    moveNewDetailItemToDataFromServer,
} = careerSlice.actions;
export default careerSlice;
