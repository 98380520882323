import axios from 'axios';
import Router from 'next/router';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { alert } from './alert';

const Axios = axios.create({
    baseURL: process.env.NEXT_PUBLIC_BASE_URL,
    timeout: 180000,
    withCredentials: true,
});

Axios.interceptors.request.use(
    config => {
        const { token } = parseCookies();
        if (!token) return config;
        config.headers['Content-Type'] = 'application/json; charset=utf-8';
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    err => Promise.reject(err),
);

Axios.interceptors.response.use(
    response => response,
    async err => {
        const {
            config,
            response: { status },
        } = err;
        const originalRequest = config;

        if (status === 419) {
            // newAuthorization = 새로운 토큰
            const { data } = await Axios.get('/api/user/refresh', {});
            const newAuthorization = data;
            // 로컬에서의 오류 때문에 조건 추가
            typeof newAuthorization !== 'object' &&
                setCookie(null, 'token', newAuthorization, {
                    maxAge: 30 * 24 * 60 * 60, // 쿠키 유효 시간(초 단위)
                    path: '/', // 쿠키 경로
                    // domain: 'localhost', // 쿠키 도메인
                    secure: true, // HTTPS에서만 쿠키 사용 여부
                    sameSite: 'none',
                });
            originalRequest.headers.Authorization = `Bearer ${newAuthorization}`;
            return Axios(originalRequest);
        }
        if (status === 417) {
            // 토큰이 만료
            // 일주일 지나면 나옴
            localStorage.clear();
            destroyCookie(null, 'token', { path: '/' });
            alert(
                '로그인 시간이 만료되었습니다. 다시 로그인 해주세요.',
                '확인',
            );
            Router.reload();
            // Router.push('/login');
            return Promise.resolve();
        }
        return Promise.reject(err);
    },
);

export default Axios;
