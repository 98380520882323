import { createAsyncThunk } from '@reduxjs/toolkit';
import Axios from 'utils/Axios';

export const getEditInfo = createAsyncThunk(
    'edit/getEditInfo',
    async (editId: number) => {
        const response = await Axios.get(`/api/${editId}/edit`);
        console.log(response.data);
        return response.data;
    },
);

// export const getEditInfo = createAsyncThunk(
//     'edit/getEditInfo',
//     async ({ editId, token }) => {
//         const response = await ssrAxios.get(`/api/${editId}/edit`, {
//             headers: {
//                 'Content-Type': 'application/json; charset=utf-8',
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//         return response.data;
//     },
// );

export const getJobList = async () => {
    console.log('~~~~~');
    const response = await Axios.get('/api/DB/jobGroupList');
    return response;
};

export const getdetailedJobList = async job_id => {
    const response = await Axios.get(
        `/api/DB/detailedJobList?job_id=${job_id}`,
    );
    return response.data;
};

export const getRegionList = async () => {
    const response = await Axios.get('/api/DB/main-region');
    console.log(response.data);
    return response.data;
};

export const getdetailedRegionList = async regionId => {
    console.log('@@@@', regionId);
    const response = await Axios.get(
        `/api/DB/detailed-region?region_id=${regionId}`,
    );
    return response.data;
};

export const getSnsOptionsList = async () => {
    const response = await Axios.get('/api/db/sns-list');
    return response.data;
};

export const getSkillList = async () => {
    const response = await Axios.get('/api/db/skill-list');
    return response;
};

//

export const postSkill = async skillId => {
    const response = await Axios.post(`/api/user/skill`, { skillId });
    console.log(response);
    return response;
};

export const postSns = async (url, favicon) => {
    const response = await Axios.post(`/api/multiLink`, { url, favicon });
    return response;
};

export const postPortfolio = async dataObj => {
    const response = await Axios.post(`/api/portfolio`, dataObj);
    return response;
};

export const postPortfolioContetns = async (
    portfolio_id: string,
    formData?: FormData,
) => {
    const response = await Axios.post(
        `/api/portfolio/${portfolio_id}/contents`,
        formData,
    );
    return response;
};

export const postCareerItem = async data => {
    const response = await Axios.post(`/api/career`, data);
    return response;
};

export const postCareerItemDetail = async data => {
    const response = await Axios.post(`/api/career/detail`, data);
    return response;
};

export const postDegreeItem = async formData => {
    const response = await Axios.post(`/api/degree`, formData);
    return response;
};

export const postEducationItem = async formData => {
    const response = await Axios.post(`/api/education`, formData);
    return response;
};

export const postCertificateItem = async formData => {
    const response = await Axios.post(`/api/certificate`, formData);
    return response;
};

export const postLanguageItem = async data => {
    const response = await Axios.post(`/api/foreignLanguage`, data);
    return response;
};

export const postSNSItem = async data => {
    const response = await Axios.post(`/api/multiLink`, data);
    return response;
};

export const postCoverletterItem = async data => {
    const response = await Axios.post(`/api/coverLetter`, data);
    return response;
};

//

export const patchUserInformation = async bodyObj => {
    const response = await Axios.patch('/api/user/user-information', bodyObj);
    return response;
};

export const patchSNSItem = async (itemID, data) => {
    const response = await Axios.patch(`/api/multiLink/${itemID}`, data);
    return response;
};

export const patchPortfolio = async (itemID, dataObj) => {
    const response = await Axios.patch(`/api/portfolio/${itemID}`, dataObj);
    return response;
};

export const patchPortfolioContents = async (
    itemID,
    contentsID,
    jsonDataObj,
) => {
    const response = await Axios.patch(
        `/api/portfolio/${itemID}/contents/${contentsID}`,
        { contents: jsonDataObj },
    );
    return response;
};

// export const patchPortfolioItem = async (
//     summaryID,
//     summaryFormData,
//     contentID,
//     contentFormData,
// ) => {
//     const response = await axios.all([
//         Axios.patch(`/api/portfolio/summary/${summaryID}`, summaryFormData),
//         Axios.patch(`/api/portfolio/content/${contentID}`, contentFormData),
//     ]);
//     return response;
// };

export const patchPortfolioOrder = async list => {
    const response = await Axios.patch('/api/portfolio/order', { list });
    return response;
};

export const patchCareerItem = async (itemID, data) => {
    const response = await Axios.patch(`/api/career/${itemID}`, data);
    return response;
};

export const patchCareerItemDetail = async (itemID, detailItemId, data) => {
    const response = await Axios.patch(
        `/api/career/${itemID}/detail/${detailItemId}`,
        data,
    );
    return response;
};

export const patchDegreeItem = async (itemID, dataObj) => {
    const response = await Axios.patch(`/api/degree/${itemID}`, dataObj);
    return response;
};

export const patchEducationItem = async (itemID, dataObj) => {
    const response = await Axios.patch(`/api/education/${itemID}`, dataObj);
    return response;
};

export const patchCertificateItem = async (itemID, dataObj) => {
    const response = await Axios.patch(`/api/certificate/${itemID}`, dataObj);
    return response;
};

export const patchLanguageItem = async (itemID, data) => {
    const response = await Axios.patch(`/api/foreignLanguage/${itemID}`, data);
    return response;
};

export const patchCoverletterOrder = async list => {
    const response = await Axios.patch(`/api/coverLetter/order`, { list });
    return response;
};

//

export const putProfileImage = async formData => {
    const response = await Axios.put('/api/user/profile-image', formData);
    return response;
};

export const putDegreeImage = async (itemId, formData) => {
    const response = await Axios.put(`/api/degree/${itemId}/image`, formData);
    return response;
};

export const putCategoryActive = async (categoryID, active) => {
    const response = await Axios.put(`/api/active/${categoryID}`, { active });
    return response;
};

export const putCategoryOrder = async data => {
    const response = await Axios.put(`/api/order`, { data });
    return response;
};

export const putCareerDetailOrder = async (itemID, data) => {
    const response = await Axios.put(`/api/career/${itemID}/detail-order`, {
        data,
    });
    return response;
};

export const putEducationOrder = async list => {
    const response = await Axios.put(`/api/education/orders`, { list });
    return response;
};

export const putEducationImage = async (itemId, formData) => {
    const response = await Axios.put(
        `/api/education/${itemId}/image`,
        formData,
    );
    return response;
};

export const putCertificateOrder = async list => {
    const response = await Axios.put(`/api/certificate/order`, { list });
    return response;
};

export const putCertificateImage = async (itemId, formData) => {
    const response = await Axios.put(
        `/api/certificate/${itemId}/image`,
        formData,
    );
    return response;
};

export const putLanguageOrder = async list => {
    console.log(list);
    const response = await Axios.put(`/api/foreignLanguage/order`, { list });
    return response;
};

export const putSNSOrder = async list => {
    const response = await Axios.put(`/api/multiLink/order`, { list });
    return response;
};

export const putProfileEmail = async email => {
    const response = await Axios.put(`/api/email`, { email });
    return response;
};

export const putPortfolioImage = async (itemID, formData) => {
    const response = await Axios.put(
        `/api/portfolio/${itemID}/image`,
        formData,
    );
    return response;
};

export const putPortfolioPdf = async (itemID, contentID, formData) => {
    const response = await Axios.put(
        `/api/portfolio/${itemID}/contents/${contentID}/image`,
        formData,
    );
    return response;
};

export const putCoverletterItem = async (id, data) => {
    console.log(id, data);
    const response = await Axios.put(`/api/coverLetter/${id}`, data);
    return response;
};

export const putCoverletterActive = async (id, active) => {
    const response = await Axios.put(`/api/coverLetter/${id}/active`, {
        active,
    });
    return response;
};

//

export const deleteProfileImage = async () => {
    const response = await Axios.delete(`/api/user/profile-image`);
    return response;
};

export const deleteSkill = async id => {
    const response = await Axios.delete(`/api/user/skill/${id}`);
    return response;
};

export const deleteSns = async id => {
    const response = await Axios.delete(`/api/multiLink/${id}`);
    return response;
};

export const deleteCareerItem = async itemID => {
    const response = await Axios.delete(`/api/career/${itemID}`);
    return response;
};

export const deleteCareerItemDetail = async (itemID, detailItemId) => {
    const response = await Axios.delete(
        `/api/career/${itemID}/detail/${detailItemId}`,
    );
    return response;
};

export const deletePortfolioItem = async itemID => {
    const response = await Axios.delete(`/api/portfolio/${itemID}`);
    return response;
};

export const deletePortfolioImage = async itemID => {
    const response = await Axios.delete(`/api/portfolio/${itemID}/image`);
    return response;
};

export const deletePortfolioPdf = async (itemID, contentID) => {
    const response = await Axios.delete(
        `/api/portfolio/${itemID}/contents/${contentID}/image`,
    );
    return response;
};

export const deleteDegreeItem = async itemID => {
    const response = await Axios.delete(`/api/degree/${itemID}`);
    return response;
};

export const deleteDegreeImage = async itemID => {
    const response = await Axios.delete(`/api/degree/${itemID}/image`);
    return response;
};

export const deleteEducationItem = async itemID => {
    const response = await Axios.delete(`/api/education/${itemID}`);
    return response;
};

export const deleteEducationImage = async itemID => {
    const response = await Axios.delete(`/api/education/${itemID}/image`);
    return response;
};

export const deleteCertificateItem = async itemID => {
    const response = await Axios.delete(`/api/certificate/${itemID}`);
    return response;
};

export const deleteCertificateImage = async itemID => {
    const response = await Axios.delete(`/api/certificate/${itemID}/image`);
    return response;
};

export const deleteLanguageItem = async itemID => {
    const response = await Axios.delete(`/api/foreignLanguage/${itemID}`);
    return response;
};

export const deleteSNSItem = async itemID => {
    const response = await Axios.delete(`/api/multiLink/${itemID}`);
    return response;
};

export const deleteCoverletterItem = async itemID => {
    const response = await Axios.delete(`/api/coverLetter/box/${itemID}`);
    return response;
};
