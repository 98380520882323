import { createSlice } from '@reduxjs/toolkit';

const initialState: { modal: boolean | string } = {
    modal: false,
};

const editModalSlice = createSlice({
    name: 'edit_modal',
    initialState,
    reducers: {
        setModalAction(state, action) {
            state.modal = action.payload;
        },
    },
});

export const { setModalAction } = editModalSlice.actions;
export default editModalSlice;
