import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    modal: false,
};

const mypageModalSlice = createSlice({
    name: 'mypage_modal',
    initialState,
    reducers: {
        setMypageModalAction(state, action) {
            state.modal = action.payload;
        },
    },
});

export const { setMypageModalAction } = mypageModalSlice.actions;
export default mypageModalSlice;
