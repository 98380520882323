import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { getEditInfo } from '../../../apis/editAPI';

const initialNewDegreeItem = {
    degreeName: '',
    major: '',
    minor: '',
    isMinor: null,
    period1: '',
    period2: '',
    Gpa: '',
    maxScore: '',
    original_image_name: '',
    fold: null,
    itemWritingRequired: true,
};

const initialState = {
    loading: true,
    degreeActive: true,
    degreeData: [],
    newItem: null,
    categoryId: null,
    degreeWritingRequired: null,
    error: null,
};

const degreeSlice = createSlice({
    name: 'edit_degree',
    initialState,
    reducers: {
        changeValue(state, action) {
            state.degreeData = state.degreeData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          [action.payload.name]: action.payload.value,
                      }
                    : item,
            );
        },
        changeValueInNewItem(state, action) {
            state.newItem[action.payload.name] = action.payload.value;
        },
        changeWritingRequired(state, action) {
            state.degreeWritingRequired = action.payload;
        },
        changeItemWritingRequired(state, action) {
            state.degreeData = state.degreeData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          itemWritingRequired: action.payload.value,
                      }
                    : item,
            );
        },
        foldItem(state, action) {
            state.degreeData = state.degreeData.map(item =>
                item.id === action.payload ? { ...item, fold: true } : item,
            );
        },
        unFoldItem(state, action) {
            state.degreeData = state.degreeData.map(item =>
                item.id === action.payload ? { ...item, fold: false } : item,
            );
        },
        createNewItem(state, action) {
            state.newItem = initialNewDegreeItem;
        },
        updateActiveState(state, action) {
            state.degreeActive = action.payload;
        },
        deleteDegreeItemAction(state, action) {
            state.degreeData = state.degreeData.filter(
                item => item.id !== action.payload,
            );

            if (state.degreeData.length === 0) {
                state.newItem = initialNewDegreeItem;
            }
        },
        deleteNewItem(state, action) {
            delete state.newItem;
        },
        moveNewItemToDataFromServer(state, action) {
            state.degreeData = [state.newItem].concat(state.degreeData);
            state.newItem = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(getEditInfo.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getEditInfo.fulfilled, (state, action) => {
            action.payload.ProfileCategories.forEach(category => {
                if (category.category_name === '5') {
                    state.categoryId = category.id;
                    state.degreeData = category.Degrees;
                    state.degreeActive = category.active;
                }
            });
            state.degreeData = state.degreeData.map(item => {
                const isNeedWriting = [
                    item.degree_name,
                    item.period1,
                    item.period2,
                ].some(item => !item);
                return {
                    ...item,
                    degreeName: item.degree_name,
                    fold: true,
                    itemWritingRequired: !!isNeedWriting,
                };
            });
            if (state.degreeData.length === 0) {
                state.newItem = initialNewDegreeItem;
            }

            const isWritingRequired = state.degreeData.some(
                item => item.itemWritingRequired,
            );

            state.degreeWritingRequired = !!isWritingRequired;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getEditInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const {
    changeValue,
    changeValueInNewItem,
    changeWritingRequired,
    changeItemWritingRequired,
    foldItem,
    unFoldItem,
    createNewItem,
    updateActiveState,
    deleteDegreeItemAction,
    deleteNewItem,
    moveNewItemToDataFromServer,
} = degreeSlice.actions;
export default degreeSlice;
