import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

@font-face {
    font-family: 'Pretendard';
    font-weight: 800;
    font-display: swap;
    src: local('Pretendard Bold'),
        url('/fonts/woff2-subset/Pretendard-ExtraBold.subset.woff2') format('woff2'),
        url('/fonts/woff-subset/Pretendard-ExtraBold.subset.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 700;
    font-display: swap;
    src: local('Pretendard Bold'),
        url('/fonts/woff2-subset/Pretendard-Bold.subset.woff2') format('woff2'),
        url('/fonts/woff-subset/Pretendard-Bold.subset.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 500;
    font-display: swap;
    src: local('Pretendard Medium'),
        url('/fonts/woff2-subset/Pretendard-Medium.subset.woff2')
            format('woff2'),
        url('/fonts/woff-subset/Pretendard-Medium.subset.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    font-display: swap;
    src: local('Pretendard Regular'),
        url('/fonts/woff2-subset/Pretendard-Regular.subset.woff2')
            format('woff2'),
        url('/fonts/woff-subset/Pretendard-Regular.subset.woff') format('woff');
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 300;
    font-display: swap;
    src: local('Pretendard Light'),
        url('/fonts/woff2-subset/Pretendard-Light.subset.woff2') format('woff2'),
        url('/fonts/woff-subset/Pretendard-Light.subset.woff') format('woff');
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    color: #000;
    font-family: 'Pretendard';
    color: inherit;
    font-size: 16px;
}

.fill {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

button {
    border: none;
    background-color: initial;
}

html,
body {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-width: 100vw;
}
body {
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: inherit;
}

a:active {
    -webkit-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
}

ul,
ol {
    list-style: none;
}

.close_btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
}

.btnArea {
    display: none !important;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    box-sizing: border-box;
    font-weight: 400;
}
b {
    font-weight: bold;
}
body {
    -ms-overflow-style: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    background-color: #fff;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

textarea:focus,
input:focus {
    outline: none;
}
select:focus {
    outline: none;
}

:root {
    --blue: #317eff;
    --purple: #9536ff;
    --badge1TextColor: #ff7f22;
    --badge1BackgroundColor: #fff8ed;
    --badge2BackgroundColor: #edf7ff;
    --buttonActiveColor: #000000;
    --subText: #4b4d4f;
    --normalInputNameText: #606975;
    --addButtonText: #606975;
    --addButtonBackground: #e6e9ee;
    --inputBorder: #e6e9ee;
    --focusedInputBorder: #000000;
    --focusedColor: #000000;
    --placeholder: #bec2c9;
    --addButtonBorder: #bec2c9;
    --snbActive: #4b4d4f;
    --saveButtonBackground: #4b4d4f;
}
/* 텍스트에디터 */
u {
    text-decoration: underline;
}
s {
    text-decoration: line-through;
}
/*  */
div {
    word-break: break-all;
    /* white-space: pre-wrap; */
}

img {
    border: none;
    outline: none;
}

/* 채널톡 */
/* #ch-plugin {
    white-space: inherit;
}

/* @supports (font: -apple-system-body) and (-webkit-appearance: none) {
    img[loading='lazy'] {
        clip-path: inset(0.6px);
    }
} */

`;

export default GlobalStyle;
