const renderCategoryName = categoryName => {
    switch (categoryName) {
        case '1':
            return '포트폴리오';
        case '2':
            return '교육 및 대외활동';
        case '3':
            return '자격증 및 수상';
        case '4':
            return '외국어';
        case '5':
            return '학력';
        case '6':
            return '업무 경력';
        default:
            return null;
    }
};

export default renderCategoryName;
