import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { getEditInfo } from '../../../apis/editAPI';

const initialState = {
    loading: true,
    dataFromServer: [],
    createItem: null,
    error: null,
};

const snsSlice = createSlice({
    name: 'basicInfo_edit_sns',
    initialState,
    reducers: {
        changeSNSItemInServer(state, action) {
            const theItem = state.dataFromServer.find(
                item => item.id === action.payload.id,
            );
            const theItemCopy = theItem;
            theItemCopy[action.payload.name] = action.payload.value;

            state.dataFromServer = state.dataFromServer.map(item =>
                item.id === action.payload.id ? theItemCopy : item,
            );
        },
        createSNSItem(state, action) {
            state.createItem = {
                url: '',
                sns_name: null,
                passed: null,
            };
        },
        deleteSNSItemInCreateList(state, action) {
            state.createItem = null;
        },
        deleteSNSItemInServer(state, action) {
            state.dataFromServer = state.dataFromServer.filter(
                item => item.id !== action.payload,
            );
        },
        moveSNSItemToDataFromServer(state, action) {
            state.dataFromServer = state.dataFromServer.concat(action.payload);
            state.createItem = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(getEditInfo.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getEditInfo.fulfilled, (state, action) => {
            state.dataFromServer = action.payload.User.MultiLinks;
            state.createItem = null;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getEditInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const {
    changeSNSItemInServer,
    createSNSItem,
    deleteSNSItemInCreateList,
    deleteSNSItemInServer,
    moveSNSItemToDataFromServer,
} = snsSlice.actions;
export default snsSlice;
