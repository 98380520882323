const formatPhoneNumber = (value: string) => {
    // 정규식을 적용하여 입력값을 가공
    const formattedValue = value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

    return formattedValue;
};

export default formatPhoneNumber;
