const seoImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/seo.png';

const growthonSeoImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/seo3.png';

const mainNewResume =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/%EB%A9%94%EC%9D%B8%EC%9D%B4%EB%AF%B8%EC%A7%80.png';

const mainOldResume =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/2023-03-30/%EA%B5%AC%EB%B2%84%EC%A0%84+%EC%9D%B4%EB%A0%A5%EC%84%9C.png';

const initialImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/2023-03-30/%EB%8C%80%ED%91%9C%EC%9D%B4%EB%AF%B8%EC%A7%80_%EA%B8%B0%EB%B3%B8.png';

const blindProfileImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/2023-03-30/blindProfileImage.png';

const blurViewImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/2023-03-30/blurViewPage.png';

const mobileBlurViewImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/2023-03-30/mobileBlurViewPage.png';

const mobileEventImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/2023-03-30/mobileFeedbackImage.png';

const portfolioPlanBasicImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/new+View%26Edit/%EA%B8%B0%ED%9A%8D.png';

const portfolioDevelopmentBasicImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/new+View%26Edit/%EA%B0%9C%EB%B0%9C.png';

const portfolioDesignBasicImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/new+View%26Edit/%EB%94%94%EC%9E%90%EC%9D%B8.png';

const portfolioMarketingBasicImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/new+View%26Edit/%EB%A7%88%EC%BC%80%ED%8C%85.png';

const portfolioEtcBasicImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/new+View%26Edit/%EA%B8%B0%ED%83%80+%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8.png';

const employmentMatchingImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/employmentModal-PC.png';

const mobileEmploymentMatchingImage =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/employmentModal-mobile.png';

const pcLogo =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/2023-0811-logo1.png';

const pcLogoWhite =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/2023-0811-logo2.png';

const mobileLogo =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/2023-0811-logo4.png';

const mobileLogoWhite =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/2023-0811-logo3.png';

const startingResumeView =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/startingResumeView.png';

const documentIcon =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/resumesMainIcon/document.svg';

const contractIcon =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/resumesMainIcon/contract.svg';

const bellIcon =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/resumesMainIcon/bell.svg';

const chatIcon =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/resumesMainIcon/chat.svg';

const lightbulbIcon =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/resumesMainIcon/lightbulb.svg';

const searchIcon =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/resumesMainIcon/search.svg';

const checkIcon =
    'https://momit-s3.s3.ap-northeast-2.amazonaws.com/img/adminUploads/defaultImage/resumesMainIcon/check.svg';

export {
    seoImage,
    growthonSeoImage,
    mainNewResume,
    mainOldResume,
    initialImage,
    blindProfileImage,
    blurViewImage,
    mobileBlurViewImage,
    mobileEventImage,
    portfolioPlanBasicImage,
    portfolioDevelopmentBasicImage,
    portfolioDesignBasicImage,
    portfolioMarketingBasicImage,
    portfolioEtcBasicImage,
    employmentMatchingImage,
    mobileEmploymentMatchingImage,
    pcLogo,
    pcLogoWhite,
    mobileLogo,
    mobileLogoWhite,
    startingResumeView,
    documentIcon,
    contractIcon,
    bellIcon,
    chatIcon,
    lightbulbIcon,
    searchIcon,
    checkIcon,
};
