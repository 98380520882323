const fontSizes = {
    extraSmall: '12px',
    small: '14px',
    medium: '16px',
    mediumLarge: '20px',
    large: '24px',
    extraLarge: '32px',
};

export default fontSizes;
