import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';
import basicInformationSlice from './slice/edit/basicInformationSlice';
import careerSlice from './slice/edit/careerSlice';
import categorySlice from './slice/edit/categorySlice';
import certificateSlice from './slice/edit/certificateSlice';
import coverletterSlice from './slice/edit/coverletterSlice';
import degreeSlice from './slice/edit/degreeSlice';
import editModalSlice from './slice/edit/editModalSlice';
import educationSlice from './slice/edit/educationSlice';
import languageSlice from './slice/edit/languageSlice';
import portfolioSlice from './slice/edit/portfolioSlice';
import snsSlice from './slice/edit/snsSlice';
import editSlice from './slice/editSlice';
import loginSlice from './slice/loginSlice';
import myPageSlice from './slice/myPage/mypage';
import mypageModalSlice from './slice/myPage/mypageModalSlice';
import viewPortfolioSlice from './slice/viewPortfolioSlice';
import viewSlice from './slice/viewSlice';

const rootReducer = combineReducers({
    edit: editSlice.reducer,
    category: categorySlice.reducer,
    basicInformation: basicInformationSlice.reducer,
    career: careerSlice.reducer,
    portfolio: portfolioSlice.reducer,
    certificate: certificateSlice.reducer,
    degree: degreeSlice.reducer,
    language: languageSlice.reducer,
    education: educationSlice.reducer,
    sns: snsSlice.reducer,
    coverletter: coverletterSlice.reducer,
    editModal: editModalSlice.reducer,
    view: viewSlice.reducer,
    viewPortfolio: viewPortfolioSlice.reducer,
    myPage: myPageSlice.reducer,
    mypageModal: mypageModalSlice.reducer,
    login: loginSlice.reducer,
});

const makeStore = () => {
    const store = configureStore({
        reducer: (state, action) => {
            switch (action.type) {
                case HYDRATE:
                    return action.payload;
                default:
                    return rootReducer(state, action);
            }
        },
        devTools: process.env.NODE_ENV !== 'production',
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    });
    return store;
};

export const wrapper = createWrapper(makeStore, {
    // debug: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore['dispatch'];

export default wrapper;
