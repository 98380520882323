import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { getEditInfo } from '../../../apis/editAPI';

const initialNewEducationItem = {
    order: null,
    education_name: '',
    evidence: null,
    original_image_name: '',
    issuing_agency: '',
    period1: '',
    period2: '',
    fold: null,
    itemWritingRequired: true,
};

const initialState = {
    loading: false,
    educationActive: true,
    educationData: [{}],
    newItem: null,
    categoryId: null,
    educationWritingRequired: null,
    error: null,
};

const educationSlice = createSlice({
    name: 'edit_education',
    initialState,
    reducers: {
        changeEducationValue(state, action) {
            const nextState = produce(state.educationData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );
                theItem[action.payload.name] = action.payload.value;
            });
            state.educationData = nextState;
        },
        changeEducationValueInNewItem(state, action) {
            const nextState = produce(state.newItem, draft => {
                draft[action.payload.name] = action.payload.value;
            });
            state.newItem = nextState;
        },
        changeWritingRequired(state, action) {
            state.educationWritingRequired = action.payload;
        },
        changeItemWritingRequired(state, action) {
            state.educationData = state.educationData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          itemWritingRequired: action.payload.value,
                      }
                    : item,
            );
        },
        foldItem(state, action) {
            state.educationData = state.educationData.map(item =>
                item.id === action.payload ? { ...item, fold: true } : item,
            );
        },
        unFoldItem(state, action) {
            state.educationData = state.educationData.map(item =>
                item.id === action.payload ? { ...item, fold: false } : item,
            );
        },
        createNewEducationItem(state, action) {
            const newItem = initialNewEducationItem;
            // newItem.order = state.educationData[0]
            state.newItem = newItem;
        },
        updateActiveState(state, action) {
            state.educationActive = action.payload;
        },
        updateEducationOrder(state, action) {
            const elem = state.educationData[action.payload.dragIndex];
            state.educationData.splice(action.payload.dragIndex, 1);
            state.educationData.splice(action.payload.hoverIndex, 0, elem);
            state.educationData.forEach((category, index) => {
                category.order = state.educationData.length - index;
            });
        },
        deleteEducationItemAction(state, action) {
            state.educationData = state.educationData.filter(
                item => item.id !== action.payload,
            );

            if (state.educationData.length === 0) {
                const newItem = { ...initialNewEducationItem };
                newItem.order = 1;
                state.newItem = newItem;
            }
        },
        deleteNewEducationItem(state, action) {
            delete state.newItem;
        },
        moveNewItemToDataFromServer(state, action) {
            state.educationData = [state.newItem].concat(state.educationData);
            state.newItem = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(getEditInfo.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getEditInfo.fulfilled, (state, action) => {
            let arr = [];
            action.payload.ProfileCategories.forEach(category => {
                if (category.category_name === '2') {
                    arr = category.Education;
                    state.categoryId = category.id;
                    state.educationActive = category.active;
                }
            });
            arr = arr.map(item => {
                const isNeedWriting = [
                    item.education_name,
                    item.period1,
                    item.period2,
                ].some(item => !item);
                return {
                    ...item,
                    // get 키값과 post 키값 동기화 (post 키값으로)
                    educationName: item.education_name,
                    issuingAgency: item.issuing_agency,
                    fold: true,
                    itemWritingRequired: !!isNeedWriting,
                };
            });
            state.educationData = arr;
            if (state.educationData.length === 0) {
                const newItem = { ...initialNewEducationItem };
                newItem.order = 1;
                state.newItem = newItem;
            }

            const isWritingRequired = state.educationData.some(
                item => item.itemWritingRequired,
            );

            state.educationWritingRequired = !!isWritingRequired;
            state.loading = false;
            state.error = null;
        });

        builder.addCase(getEditInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const {
    changeEducationValue,
    changeEducationValueInNewItem,
    changeWritingRequired,
    changeItemWritingRequired,
    foldItem,
    unFoldItem,
    createNewEducationItem,
    updateActiveState,
    updateEducationOrder,
    deleteEducationItemAction,
    deleteNewEducationItem,
    moveNewItemToDataFromServer,
} = educationSlice.actions;
export default educationSlice;
