import { createSlice } from '@reduxjs/toolkit';
import { getEditInfo } from '../../apis/editAPI';

const initialState = {
    loading: true,
    data: {},
    updateDate: null,
    error: null,
};

const editSlice = createSlice({
    name: 'edit',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getEditInfo.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getEditInfo.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
            state.updateDate =
                action.payload.User.profileUpdateAts[0].updatedAt;
        });
        builder.addCase(getEditInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export default editSlice;
