const filterValidData = (data, requiredKeyArr) => {
    const filteredData = data.filter(item => {
        const isIncomplete = requiredKeyArr.some(key => !item[key]);
        return !isIncomplete;
    });

    return filteredData;
};

export default filterValidData;
