import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { getEditInfo } from '../../../apis/editAPI';

const initialNewLanguageItem = {
    order: null,
    foreignLanguageName: '',
    level: '',
    fold: null,
    itemWritingRequired: true,
};

const initialState = {
    loading: false,
    languageActive: true,
    languageData: [
        {
            id: null,
        },
    ],
    newItem: null,
    categoryId: null,
    languageEditState: null,
    languageWritingRequired: null,
    error: null,
};

const languageSlice = createSlice({
    name: 'edit_language',
    initialState,
    reducers: {
        changeLanguageValue(state, action) {
            const nextState = produce(state.languageData, draft => {
                const theItem = draft.find(
                    item => item.id === action.payload.itemID,
                );
                theItem[action.payload.name] = action.payload.value;
            });
            state.languageData = nextState;
        },
        changeLanguageValueInNewItem(state, action) {
            const nextState = produce(state.newItem, draft => {
                draft[action.payload.name] = action.payload.value;
            });
            state.newItem = nextState;
        },
        changeWritingRequired(state, action) {
            state.languageWritingRequired = action.payload;
        },
        changeItemWritingRequired(state, action) {
            state.languageData = state.languageData.map(item =>
                item.id === action.payload.itemID
                    ? {
                          ...item,
                          itemWritingRequired: action.payload.value,
                      }
                    : item,
            );
        },
        foldItem(state, action) {
            state.languageData = state.languageData.map(item =>
                item.id === action.payload ? { ...item, fold: true } : item,
            );
        },
        unFoldItem(state, action) {
            state.languageData = state.languageData.map(item =>
                item.id === action.payload ? { ...item, fold: false } : item,
            );
        },
        createNewLanguageItem(state, action) {
            state.newItem = initialNewLanguageItem;
        },
        updateActiveState(state, action) {
            state.languageActive = action.payload;
        },
        updateLanguageOrder(state, action) {
            const elem = state.languageData[action.payload.dragIndex];
            state.languageData.splice(action.payload.dragIndex, 1);
            state.languageData.splice(action.payload.hoverIndex, 0, elem);
            state.languageData.forEach((category, index) => {
                category.order = state.languageData.length - index;
            });
        },
        deleteLanguageItemAction(state, action) {
            state.languageData = state.languageData.filter(
                item => item.id !== action.payload,
            );

            if (state.languageData.length === 0) {
                state.newItem = initialNewLanguageItem;
            }
        },
        deleteNewLanguageItem(state, action) {
            delete state.newItem;
        },
        moveNewItemToDataFromServer(state, action) {
            state.languageData = [state.newItem].concat(state.languageData);
            state.newItem = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(getEditInfo.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getEditInfo.fulfilled, (state, action) => {
            let arr = [];
            action.payload.ProfileCategories.forEach(category => {
                if (category.category_name === '4') {
                    arr = category.ForeignLanguages;
                    state.categoryId = category.id;
                    state.languageActive = category.active;
                }
            });
            arr = arr.map(item => {
                const isNeedWriting = [
                    item.foreign_language_name,
                    item.level,
                ].some(item => !item);
                return {
                    ...item,
                    foreignLanguageName: item.foreign_language_name,
                    level: item.level,
                    fold: true,
                    itemWritingRequired: !!isNeedWriting,
                };
            });
            state.languageData = arr;
            if (state.languageData.length === 0) {
                state.newItem = initialNewLanguageItem;
            }

            const isWritingRequired = state.languageData.some(
                item => item.itemWritingRequired,
            );

            state.languageWritingRequired = !!isWritingRequired;
            state.loading = false;
            state.error = null;
        });
        builder.addCase(getEditInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const {
    changeLanguageValue,
    changeLanguageValueInNewItem,
    changeWritingRequired,
    changeItemWritingRequired,
    foldItem,
    unFoldItem,
    createNewLanguageItem,
    updateActiveState,
    updateLanguageOrder,
    deleteLanguageItemAction,
    deleteNewLanguageItem,
    moveNewItemToDataFromServer,
} = languageSlice.actions;
export default languageSlice;
