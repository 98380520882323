import { createSlice } from '@reduxjs/toolkit';
import renderRequiredKeyArr from 'assets/data/requriedItemsInCategory';
import produce from 'immer';
import {
    ActiveCategory,
    ProfileCategory,
    ViewDataState,
} from 'types/viewTypes';
import filterValidData from 'utils/filterValidData';
import renderCategoryName from 'utils/renderCategoryName';
import { getViewInfo } from '../../apis/viewAPI';

const initialState: ViewDataState = {
    loading: true,
    data: {
        isMine: false,
        profile: {
            User: {
                position_offer_status: 0,
                MultiLinks: [],
            },
        },
    },
    portfolioActive: true,
    portfolioData: [],
    onlyActiveResumeData: [],
    categoryData: [],
    coverletterActive: true,
    coverletterData: [],
    error: null,
};

const viewSlice = createSlice({
    name: 'view',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getViewInfo.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getViewInfo.fulfilled, (state, action) => {
            const arr: ActiveCategory[] = [];
            state.loading = false;
            state.error = null;
            state.data = action.payload;
            console.log(action.payload);
            //
            let activeCategoryArr =
                action.payload.profile.ProfileCategories.filter(
                    (category: ProfileCategory) =>
                        category.category_name !== '1' && category.active,
                );
            activeCategoryArr = activeCategoryArr.filter(
                (category: ProfileCategory) => {
                    const {
                        category_name,
                        Careers,
                        Certificates,
                        Degrees,
                        Education,
                        ForeignLanguages,
                    } = category;
                    if (category_name === '2' && Education.length !== 0) {
                        const filteredData = filterValidData(
                            Education,
                            renderRequiredKeyArr(category_name),
                        );
                        return filteredData.length !== 0;
                    }
                    if (category_name === '3' && Certificates.length !== 0) {
                        const filteredData = filterValidData(
                            Certificates,
                            renderRequiredKeyArr(category_name),
                        );
                        return filteredData.length !== 0;
                    }
                    if (
                        category_name === '4' &&
                        ForeignLanguages.length !== 0
                    ) {
                        const filteredData = filterValidData(
                            ForeignLanguages,
                            renderRequiredKeyArr(category_name),
                        );
                        return filteredData.length !== 0;
                    }
                    if (category_name === '5' && Degrees.length !== 0) {
                        const filteredData = filterValidData(
                            Degrees,
                            renderRequiredKeyArr(category_name),
                        );
                        return filteredData.length !== 0;
                    }
                    if (category_name === '6' && Careers.length !== 0) {
                        const filteredData = filterValidData(
                            Careers,
                            renderRequiredKeyArr(category_name),
                        );
                        return filteredData.length !== 0;
                    }
                    return false;
                },
            );
            state.onlyActiveResumeData = activeCategoryArr;
            //
            activeCategoryArr.forEach((category: ProfileCategory) => {
                arr.push({
                    id: category.id,
                    text: renderCategoryName(category.category_name),
                });
            });
            state.categoryData = arr;
            //
            action.payload.profile.ProfileCategories.forEach(
                (category: ProfileCategory) => {
                    if (category.category_name === '1') {
                        state.portfolioActive = category.active;
                        console.log(category.Portfolios);
                        state.portfolioData = category.Portfolios;
                    }
                },
            );
            //
            const { CoverLetters } = action.payload.profile;
            if (
                !CoverLetters[0]?.active ||
                CoverLetters[0]?.CoverLetterDetails.length === 0
            ) {
                state.coverletterActive = false;
                state.coverletterData = [];
            } else {
                state.coverletterActive = true;
                state.coverletterData =
                    action.payload.profile.CoverLetters[0].CoverLetterDetails;
            }
        });
        builder.addCase(getViewInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export default viewSlice;
