import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Axios from 'utils/Axios';

export const getMyPageInformation = createAsyncThunk(
    'myPage/getMyPageInformation',
    async () => {
        const response = await Axios.get(`/api/user/my-page`);
        return response.data[0];
    },
);

export async function putMyPageName(ownerName) {
    const response = await Axios.put('/api/user/name', { ownerName });
    return response;
}

export async function putMyPagePassword(currentPassword, newPassword, type) {
    if (!type) type = 'normal';
    const response = await Axios.put('/api/user/my-page/password', {
        currentPassword,
        newPassword,
        type,
    });
    return response;
}

export async function postMyPageWithdrawal(feedBack) {
    const response = await Axios.post(`/api/user/withdrawal`, { feedBack });
    return response;
}

export async function putDomain(profilesId) {
    const response = await Axios.put(`/api/domain`, { profilesId });
    return response;
}

export async function putTel(tel) {
    const response = await Axios.put(`/api/user/tel`, { tel });
    return response;
}

export async function unLinkSns() {
    const response = await Axios.put(`/api/user/unlink-sns`);
    return response;
}

export const offerPosition = async status => {
    const response = await Axios.put('/api/user/position-offer-status', {
        status,
    });
    return response;
};

export const putMarketingAllow = async allowMailing => {
    const response = await Axios.put('/api/user/allow-mailing', {
        allowMailing,
    });
    return response;
};

export const postFeedback = async data => {
    const response = await Axios.post('/api/user/feedback', data);
    return response;
};

export const clearKakaoLinkForKakaoServer = async form => {
    // 이 유형의 함수 여러 개 생기면 새 Axios 같은 유틸함수 만들기
    const response = await axios.post(
        'https://kapi.kakao.com/v1/user/unlink',
        form,
        {
            headers: {
                Authorization: `KakaoAK ${process.env.NEXT_PUBLIC_KAKAO_ADMIN_KEY}`,
                'Content-type':
                    'application/x-www-form-urlencoded;charset=utf-8',
            },
        },
    );
    return response;
};
